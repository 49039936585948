import Axios from 'axios'
import showToast from '@/global_components/Toast/useToast';
import { useAuthStore } from '@/store/auth';

function getLeftmostSubdomain() {
    const NON_TENANT_SUBDOMAINS = [
        'www',
        'dev',
        'test',
        'staging',
        'platform',
        'signup',
        'apis',
        'admin',
        'glasshub',
    ];

    const hostname = window.location.hostname;

    // Split the hostname into parts.
    const parts = hostname.split('.');

    // Handle special case for localhost or any single-part hostnames.
    if (parts.length <= 1) {
        return null;
    }

    // Extract the leftmost part (subdomain) of the hostname.
    const leftmostSubdomain = parts[0];

    // Check if the leftmost subdomain is in the NON_TENANT_SUBDOMAINS list.
    if (NON_TENANT_SUBDOMAINS.includes(leftmostSubdomain)) {
        return null;
    }

    return leftmostSubdomain;
}

function tenantAccessError(status_ = null) {
    let message = "Access Denied! This resource is not available or doesn't exist.";

    if (status_ === 'suspended') {
        message = `
            Access Denied! This tenant account has been suspended.
            <br>
            Please contact customer care:
            <a href="mailto:customer1st@glasshub.ai">customer1st@glasshub.ai</a>
        `;
    }
    else if (status_ === 'not_init') {
        message = `
            Access Denied! This account has not been initialized yet.
            <br>
            Please contact customer care:
            <a href="mailto:customer1st@glasshub.ai">customer1st@glasshub.ai</a>
        `;
    }

    document.open();
    document.write(`<h2 style="color:#5241a1;padding:1.87rem;line-height:1.5;font-family:sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, sans-serif !important;">${message}</h2>`);
}

const axios_instance = Axios.create({
    baseURL: process.env.VUE_APP_API_PATH,
});

const tenantSubdomain = getLeftmostSubdomain();
axios_instance.defaults.headers.common['x-access-token'] = localStorage.getItem('user_access_token');

axios_instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error.response?.data) {
        if (error.response?.data.auth == false) {
            localStorage.clear()
            window.open('/login', '_self');
            // window.location.href='/login'
            return false
        }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.message === 'Network Error' && !error.response) {
        const store = useAuthStore()
        store.loader = false
        showToast('internet error / server not respond')
        // return error
    } else {
        return Promise.reject(error);
    }
});

console.info({tenantSubdomain});

if (tenantSubdomain) {
    axios_instance.defaults.headers.common['x-tenant-subdomain'] = tenantSubdomain;

    axios_instance.get('/api/tenants/management/check')
        .then((res) => {
            const { is_activated, is_init } = (res.data?.tenant ?? {});

            if (false === is_activated) {
                tenantAccessError('suspended');
            }
            else if (false === is_init) {
                tenantAccessError('not_init');
            }

            // console.info({is_activated, ...res.data})
        })
        .catch((err) => {
            if (403 === err.response.status) {
                tenantAccessError();
            }
        });
}

export default axios_instance;
