import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n'
import App from './App.vue';
import router from '@/router/routes';
import Toaster from '@meforma/vue-toaster';
import Toast from 'vue3-toast-single'
import CanvasJSChart from '@canvasjs/vue-charts';
/*! purgecss start ignore */
import 'vue3-toast-single/dist/toast.css'
/* csspurge end ignore */
import vue3GoogleLogin from 'vue3-google-login'
import VPagination from "@hennge/vue3-pagination";
/*! purgecss start ignore */
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
/* csspurge end ignore */
import VueSweetalert2 from 'vue-sweetalert2';
/*! purgecss start ignore */
import 'sweetalert2/dist/sweetalert2.min.css';
/* csspurge end ignore */
// import Socket from './global_components/webSocket/chat_websocket_send';
import VNetworkGraph from "v-network-graph"
import  "v-network-graph/lib/style.css"
import "bootstrap";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import print from 'vue3-print-nb'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from 'vue-select'
import VueApexCharts from "vue3-apexcharts";

const pinia = createPinia();

pinia.use(({ store }) => {
    store.$router = markRaw(router);
});

const i18n = createI18n({
  locale:localStorage.getItem('lang') || 'en',
  messages: {
    en: {
      Username :'User name',
      Password:'Password',
      AccountType:'Account Type',
      create:'Create an Account',
      Signup:'Sign up',
      Login:'Login',
      FullName:'Full Name',
      PhoneNumber:'Phone Number',
      Email:'Email',
      Freelancer:'Freelancer',
      Auditfirms:'Audit firms',
      Suppliers :'Suppliers ',
      Regulator:'Regulator',
      Organization:'Organization',
      Dashboard:'Dashboard',
      projects:'projects',
      Certificate:'Certificate',
      Compliance:'Compliance',
      Finance:'Finance',
      Reports:'Reports',
      IDcompliance:'ID compliance',
      Accounttype:'Account type',
      Compliancename:'Compliance name',
      DefinedCompliance:'Defined Compliance',
      Date:'Date',
      Action:'Action',
      Ps:'Post project to supplier',
      Pf:'Post project to freelancer',
      Pq:'Post project for Quotation',
      Pendingprojects:'Pending projects',
      Ip:'In process projects',
      Completedprojects:'Completed projects',
      Cancelledprojects:'Cancelled projects',
      ProjectName:'Project name',
      af:'Authorized Audit Firms',
      fp:'Freelancer can bid project',
      Cancelled:'Cancelled',
      Inprocess:'In process',
      Pending:'Pending',
      All:'All'
    },
    ar: {
      Username :'اسم المستخدم',
      Password:'كلمة السر',
      AccountType:'حساب',
      create:'جديد! إنشاء حساب',
      Signup:'تسجيل',
      Login:'دخول',
      FullName:'الإسم الكامل',
      PhoneNumber:'الجوال',
      Email:'البريد الالكتروني',
      Freelancer:'الأشخاص المستقلين',
      Auditfirms:'شركات التدقيق',
      Suppliers :'الموردين ',
      Regulator:'جهة حكومية',
      Organization:'شركة',
      Dashboard:'الصفحة الرئيسية',
      projects:'المشاريع',
      Certificate:'الشهادات',
      Compliance:'الضوابط ',
      Finance:'المالية',
      Reports:'التقارير',
      IDcompliance:'رقم الضابط',
      Accounttype:'نوع الحساب',

      Compliancename:'اسم الضابط',
      DefinedCompliance:'الضابط',
      Date:'التاريخ',
      Action:'الاجراءات',
      Ps:'مشروع الى مورد',
      Pf:'مشروع الى الأشخاص المستقلين',
      Pq:'عرض سعر لمشروع ',
      Pendingprojects:'المشاريع المعلقة',
      Ip:'مشاريع تحت التنفيذ',
      Completedprojects:'مشاريع منجزة',
      Cancelledprojects:'مشاريع ملغاة',
      ProjectName:'اسم المشروع',
      af:'شركة التدقيق المعتمدة',
      fp:'يمكن للشخص المستقل التقديم على المشروع',
      Cancelled:'ملغاة',
      Inprocess:'تحت التنفيذ',
      Pending:'معلقة',
      All:'الكل'
    }
  }
});

const app = createApp(App);

app.use(VueGoogleMaps,{
  load: {
      // 'YOUR_API_KEY_COMES_HERE'
      // 'AIzaSyDNS7nXnbp8nqbAzU5h1nuqAK5kaWAsP90'
      key: 'AIzaSyAdxmehf5dM8SXOKluCcljMb6QfzoNjv1g',
      libraries: "places"
  },
});

app.use(print)
app.use(router)
app.use(pinia)
app.use(VueApexCharts)
app.use(i18n)
// app.use(Socket())
app.component('v-select', vSelect)
app.use(Toaster,{timeout: 3000})
app.use(VueSweetalert2)
app.use(CanvasJSChart)
app.use(Toast, { verticalPosition: 'top', duration: 3500, horizontalPosition: 'right', transition: 'transition' })
app.use(VPagination);

app.use(vue3GoogleLogin, {
    clientId: '659906570953-446v9i9u0i5ije6pouh1iqq0dmf9kj7s.apps.googleusercontent.com'
});

app.use({
baseUrl: process.env.VUE_APP_REDIRECT_URL, // Your API domain
providers: {
    github: {
    clientId: '',
    redirectUri: process.env.VUE_APP_REDIRECT_URL+'/sign-up/type' // Your client app URL
    },
    linkedin: {
    name: 'linkedin',
    url: '/auth/linkedin',
    authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/accessToken',
    redirectUri: process.env.VUE_APP_REDIRECT_URL,
    requiredUrlParams: ['state'],
    scope: ['r_emailaddress'],
    scopeDelimiter: ' ',
    state: 'STATE',
    oauthType: '2.0',
    popupOptions: { width: 527, height: 582 },
    },
}
});

app.use(VNetworkGraph);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');

export default app;
